import React, { useState, useEffect } from "react";
import {
  Grid,
  Hidden,
  Input,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @ts-ignore
import FilterIcon from "./img/FilterIcon.svg";
// @ts-ignore
import LocationIcon from "./img/Location.png";
// @ts-ignore
import DownChevron from "./img/chevron-down.svg";
// @ts-ignore
import SearchIcon from "./img/search-icon.svg";
import AdvancedFiltersButton from "./adviserAdvPopup";
// @ts-ignore
import AdvancedFilterIconWhite from "./img/FilterIconWhite.svg";
// @ts-ignore
import CloseArrow from "./img/CloseArrow.svg";
import Container from "../../components/container";
// @ts-ignore
import CloseSquare from "./img/CloseSquare.svg";
import CampusCTA from "./blocks/reuseableCampusComponents/campusCTA";

const AdvancedMobileForm = ({
  classes,
  handleAdvancePress,
  SpecialisationDropdown,
  specialisationChange,
  specialisationLabel,
  styles,
  QualificationDropdown,
  qualificationsChange,
  qualificationLabel,
  genderChange,
  genderLabel,
  genderDropdownOptions,
  feeStructureChange,
  feeStructureLabel,
  feeStructureDropdownOptions,
  handleSearch,
  handleSetUserRadius,
  isCoursePage
}) => {
  return (
    <Grid className={classes.backdrop}>
      <Grid container className={classes.advContainerForm}>
        <Grid container>
          <Grid item xs={11}>
            <h4 className={classes.advFormTitle}>Advanced Filters</h4>
          </Grid>
          <Grid item xs={1}>
            <img src={CloseSquare} onClick={handleAdvancePress} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SpecialisationDropdown
            specialisationChange={specialisationChange}
            // customClassName={styles.filterDropdownLocationMobile}
            customClassName={isCoursePage ? styles.filterDropdownLocationMobileCourse : styles.filterDropdownLocationMobile}
            specialisationLabel={specialisationLabel}
            isCoursePage={isCoursePage}
          />
        </Grid>
        <Grid item xs={12}>
          <QualificationDropdown
            qualificationsChange={qualificationsChange}
            // customClassName={styles.filterDropdownLocationMobile}
            customClassName={isCoursePage ? styles.filterDropdownLocationMobileCourse : styles.filterDropdownLocationMobile}
            qualificationLabel={qualificationLabel}
          />
        </Grid>
        <Grid item xs={12}>
          <select
            className={isCoursePage ? styles.filterDropdownLocationMobileCourse : styles.filterDropdownLocationMobile}
            id="gender-dropdown"
            onChange={genderChange}
            value={genderLabel}
          >
            {genderDropdownOptions.map((option) => (
              <option value={option.Value}>{option.Label}</option>
            ))}
          </select>
        </Grid>
        <Grid item xs={12}>
          <select
            // className={styles.filterDropdownLocationMobile}
            className={isCoursePage ? styles.filterDropdownLocationMobileCourse : styles.filterDropdownLocationMobile}
            id="fee-structure-dropdown"
            onChange={feeStructureChange}
            value={feeStructureLabel}
          >
            {feeStructureDropdownOptions.map((option) => (
              <option value={option.Value}>{option.Label}</option>
            ))}
          </select>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            className={classes.mobileSearch}
            id="search-button"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
const AdviserFilters = ({
  styles,
  LocationDropdown,
  locationChange,
  QualificationDropdown,
  qualificationsChange,
  SpecialisationDropdown,
  handleSearch,
  feeStructureChange,
  specialisationChange,
  feeStructureDropdownOptions,
  genderChange,
  genderDropdownOptions,
  sortByChange,
  qualificationLabel,
  specialisationLabel,
  feeStructureLabel,
  genderLabel,
  handleSetUserRadius,
  isCoursePage,
  isGoogleMapsLoaded,
  setIsGoogleMapsLoaded
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      position: "fixed",
      width: 400,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      zIndex: 999,
      borderRadius: "8px",
      padding: "24px",
      border: "1px solid #DBE3EF",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      [theme.breakpoints.down("xs")]: {
        width: 335,
        padding: "16px",
      },
      [theme.breakpoints.down(345)]: {
        width: 300,
        padding: "16px",
      },
    },
    advContainerForm: {
      borderRadius: "8px",
      width: "220px",
      [theme.breakpoints.down("lg")]: {
        // maxWidth: "150px",
        // maxHeight: "150px",
        width: "100%",
      },
    },
    advFormTitle: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "28px",
      /* or 140% */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "-0.02em",

      /* Titles */

      color: "#262641",
      marginTop: 0,
      marginBottom: "1rem",
    },
    mobileSearch: {
      height: "48px",
      background: "#DBE3EF",
      borderRadius: "8px",
      textTransform: "uppercase",
      width: "100%",
    },
  }));

  const classes = useStyles();

  const handleAdvancePress = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleDesktopAdvancePress = () => {
    if (!desktopOpen) {
      setDesktopOpen(true);
    } else {
      setDesktopOpen(false);
    }
  };

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        justify="space-between"
        className={isCoursePage ? styles.adviserHeaderBlockCampus : styles.adviserHeaderBlock}
      >
        <Grid item md={1}></Grid>
        <Grid
          item
          xs={12}
          md={10}
          id={"adviser-header"}
          className={styles.headerBlockItemContainer}
        >
          <Grid item xs={12} className={styles.headerBlockTitleContainer}>
            <p className={styles.headerBlockTitle}>
              {isCoursePage ? "Find an Expert" :  "Find an adviser"}
            </p>
            <Hidden smDown>
              <p className={styles.headerBlockSubTitle}>
                Enter your location or use the advanced filters to find the perfect match.
              </p>
            </Hidden>
          </Grid>
          <Grid className={styles.holderForAdvInputs}>
              <LocationDropdown locationChange={locationChange} isCoursePage={isCoursePage} />
            <Grid
              item
              xs={12}
              md={3}
              className={styles.headerBlockFormContainer}
            >
              <div>
                <select
                  id="ddLocation"
                  className={isCoursePage ? styles.filterDropdownLocationCampus : styles.filterDropdownLocation}
                  onChange={handleSetUserRadius}
                >
                  <option value="10" selected>Distance (10 miles)</option>
                  <option value="20">Distance (20 miles)</option>
                  <option value="30">Distance (30 miles)</option>
                  <option value="40" selected>Distance (40 miles)</option>
                  <option value="50">Distance (50 miles)</option>
                  <option value="100">Distance (100 miles)</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} md={2} className={isCoursePage ? styles.campusButtonHolder : null}>

              {isCoursePage ? <CampusCTA linkText={"Search"} buttonType={"rectangular"} pageType={"available courses"} arrowIcon={false} customClickEvent={handleSearch}></CampusCTA> : <Button
                className={styles.headerBlockButton}
                id="search-button"
                onClick={handleSearch}
                >
                <img className={styles.searchIcon} src={SearchIcon} />
                Search
                </Button>
              }
            </Grid>
          </Grid>
          <Hidden smDown>
            {desktopOpen ? (
              <>
                <span
                  className={styles.desktopAdvFiltersButton}
                  onClick={handleDesktopAdvancePress}
                >
                  <img className={styles.whiteCloseIcon} src={CloseArrow} />{" "}
                  Close
                </span>
                <Grid container className={styles.containerForDesktopAdv}>
                  <Grid item xs={isCoursePage ? 3 : 4}>
                    <SpecialisationDropdown
                      isCoursePage={isCoursePage}
                      specialisationChange={specialisationChange}
                      customClassName={isCoursePage ? styles.filterDropdownLocationCampus : styles.filterDropdownLocation}
                      specialisationLabel={specialisationLabel}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <QualificationDropdown
                      qualificationsChange={qualificationsChange}
                      customClassName={isCoursePage ? styles.filterDropdownLocationCampus : styles.filterDropdownLocation}
                      qualificationLabel={qualificationLabel}
                    />
                  </Grid>
                  {!isCoursePage &&
                  <Grid item xs={3}>
                    <select
                      className={isCoursePage ? styles.filterDropdownLocationCampus : styles.filterDropdownLocation}
                      id="gender-dropdown"
                      onChange={genderChange}
                      value={genderLabel}
                    >
                      {genderDropdownOptions.map((option) => (
                        <option value={option.Value}>{option.Label}</option>
                      ))}
                    </select>
                  </Grid>
                  }
                  <Grid item xs={3}>
                    <select
                      className={isCoursePage ? styles.filterDropdownLocationCampus : styles.filterDropdownLocation}
                      id="fee-structure-dropdown"
                      onChange={feeStructureChange}
                      value={feeStructureLabel}
                    >
                      {feeStructureDropdownOptions.map((option) => (
                        <option value={option.Value}>{option.Label}</option>
                      ))}
                    </select>
                  </Grid>
                  {
                    isCoursePage &&
                    <Grid item xs={3}>
                      <CampusCTA linkText={"Apply filters"} buttonColour={"grey"} buttonType={"rectangular"} pageType={"available courses"} arrowIcon={false} customClickEvent={handleSearch}></CampusCTA>
                    </Grid>
                  }
                </Grid>
              </>
            ) : (
              <span
                className={styles.desktopAdvFiltersButton}
                onClick={handleDesktopAdvancePress}
              >
                <img
                  className={styles.whiteAdvFiltersIcon}
                  src={AdvancedFilterIconWhite}
                />{" "}
                Advanced Filters
              </span>
            )}
          </Hidden>
        </Grid>

        <Grid item md={1}></Grid>
      </Grid>
      <Hidden mdUp>
        <>
          {" "}
          <div
            className={styles.advFiltersContainer}
            id="filters"
            onClick={handleAdvancePress}
          >
            <img className={styles.filterIcon} src={FilterIcon} />

            <span className={styles.advFilterText}>Advanced Filters</span>
          </div>
          {open && (
            <Dialog open={open}>
              <AdvancedMobileForm
                classes={classes}
                handleAdvancePress={handleAdvancePress}
                qualificationLabel={qualificationLabel}
                styles={styles}
                SpecialisationDropdown={SpecialisationDropdown}
                specialisationChange={specialisationChange}
                specialisationLabel={specialisationLabel}
                QualificationDropdown={QualificationDropdown}
                qualificationsChange={qualificationsChange}
                genderChange={genderChange}
                genderLabel={genderLabel}
                genderDropdownOptions={genderDropdownOptions}
                feeStructureChange={feeStructureChange}
                feeStructureLabel={feeStructureLabel}
                feeStructureDropdownOptions={feeStructureDropdownOptions}
                handleSearch={handleSearch}
                handleSetUserRadius={handleSetUserRadius}
                isCoursePage={isCoursePage}
              ></AdvancedMobileForm>
            </Dialog>
          )}
        </>
      </Hidden>
    </>
  );
};

export default AdviserFilters;
